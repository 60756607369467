<template>
  <v-container v-if="!loading">
    <v-row justify="center">
      <v-col cols="12" sm="1">
        <v-btn fab small color="accent" to="/coupons/new?wizard=true">
          <v-icon>add</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <h4>List of promotions</h4>
      </v-col>
      <v-col cols="6">
        <v-switch
          v-model="showExpired"
          label="Show Expired Promotions"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row v-if="!promotions || promotions.length == 0">
      <v-alert outlined type="info">Currently no promition</v-alert>
    </v-row>
    <v-row v-else>
      <v-col
        class="pr-1"
        cols="12"
        sm="4"
        v-for="(promo, index) in promotions"
        :key="index"
      >
        <v-card :to="'/coupons/edit/' + promo.id" height="230">
          <v-card-title primary-title>
            <v-row>
              <v-col cols="12">
                <h3>{{ promo.title }}</h3>
              </v-col>
              <v-col cols="12">
                <h4>
                  Category:
                  <span class="info--text">{{
                    promotionCategory(promo) | capitalize
                  }}</span>
                </h4>
                <h4>Type: {{ promotionType(promo) }} discount</h4>
                <h4>
                  Status: {{ promo.status }}
                  <v-icon color="red" v-if="promo.status === 'pause'" right
                    >pause</v-icon
                  >
                  <v-icon color="success" v-if="promo.status === 'active'" right
                    >play_arrow</v-icon
                  >
                </h4>

                <h4>
                  Value: {{ promo.amount_type === '$' ? '$' : ''
                  }}{{ promo.amount }}{{ promo.amount_type === '%' ? '%' : '' }}
                </h4>
                <h4 v-if="isExpired(promo)" class="red--text justify-center">
                  Expired
                </h4>
                <h4 v-else-if="promo.end_date">
                  Expires: {{ $moment(promo.end_date).fromNow() }}
                </h4>

                <h5
                  v-if="
                    promo.promo_category == 'multiple_use' &&
                      promo.list &&
                      promo.list.length == 1
                  "
                >
                  CODE: <strong> {{ promo.list[0] }}</strong>
                </h5>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

import moment from 'moment'
const now = moment()

export default {
  computed: {
    ...mapGetters({
      loading: 'loading',
      profile: 'profile',
      isAdmin: 'isAdmin'
    }),
    promotions() {
      if (!this.showExpired) {
        return this.list.filter(p => !this.isExpired(p))
      }
      return this.list
    }
  },
  data() {
    return {
      uid: null,
      showExpired: false,
      list: []
    }
  },
  mounted() {
    this.uid = this.isAdmin ? 'TAKEIN' : this.profile ? this.profile.id : null
    console.log('Pomotions for uid: ', this.uid)
    this.getList()
  },
  methods: {
    getList() {
      this.$store
        .dispatch('getPromotions', { load: true, uid: this.uid })
        .then(list => {
          this.list = list || []
        })
    },
    promotionCategory(data) {
      return data.promo_category
        ? data.promo_category.replace('_', ' ')
        : 'Multi Use'
    },
    promotionType(data) {
      return data.promo_type ? data.promo_type.value : 'price'
    },
    isExpired(data) {
      return data.end_date
        ? this.$moment(data.end_date).diff(now, 'days') < 0
        : false
    }
  }
}
</script>
